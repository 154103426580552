<template>
    <div>
        <div class="frame-box">
            <div class="frame-chi-tiet-hoat-dong-xe">
                <div class="row">
                    <div
                        class="font-20 font-medium title"
                        id="header_BCHoatDongXeTimeline_CaiDatHienThi_CaiDatHienThi"
                    >
                        Cài đặt hiển thị
                    </div>
                </div>
                <div class="row mt-4">
                    <DxDataGrid
                        class="table-page"
                        height="302"
                        id="table_BCHoatDongXeTimeline_CaiDatHienThi_ThongTinHienThi"
                        :data-source="dataSource"
                        :show-borders="true"
                        :rowDragging="rowDragging"
                    >
                        <DxSelection
                            :allowSelectAll="true"
                            :deferred="false"
                            :fixed="true"
                            mode="multiple"
                            showCheckBoxesMode="always"
                            selectAllMode="allPage"
                        />
                        <DxColumn
                            data-field="text"
                            caption="Nội dung hiển thị"
                        />
                        <!-- <DxColumn data-field="City" :fixed="true" caption="City" />
                        <DxColumn data-field="State" caption="State" />
                        <DxColumn data-field="Phone" caption="Phone" />
                        <DxColumn data-field="Fax" caption="Fax" />-->
                    </DxDataGrid>
                </div>
                <div class="row mt-4">
                    <DxButton
                        text="Mặc định"
                        type="normal"
                        stylingMode="outlined"
                        id="button_BCHoatDongXeTimeline_CaiDatHienThi_MacDinh"
                    ></DxButton>
                </div>
                <div class="row justify-space-between mt-4">
                    <DxButton
                        text="HỦY"
                        style="text-transform: uppercase"
                        styling-mode="text"
                        type="default"
                        id="button_BCHoatDongXeTimeline_CaiDatHienThi_Huy"
                        @click="hiddenDialog()"
                    ></DxButton>
                    <DxButton
                        width="94"
                        text="LƯU"
                        id="button_BCHoatDongXeTimeline_CaiDatHienThi_Lưu"
                        style="text-transform: uppercase"
                        styling-mode="contained"
                        type="default"
                    ></DxButton>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { DxButton } from "devextreme-vue";
import DxDataGrid, { DxColumn, DxSelection } from "devextreme-vue/data-grid";

export default {
    components: {
        DxDataGrid,
        DxSelection,
        DxColumn,
        DxButton,
    },
    data() {
        return {
            rowDragging: {
                allowDropInsideItem: true,
                allowReordering: true,
                onReorder: this.onReorder,
                showDragIcons: true,
            },
            dataSource: [
                {
                    text: "Hình thức chạy",
                },
                {
                    text: "Đủ điều kiện lên nốt",
                },
                {
                    text: "Hạn đăng kiểm",
                },
                {
                    text: "Hạn bảo hiểm",
                },
                {
                    text: "Lái xe",
                },
                {
                    text: "Phụ xe",
                },
                {
                    text: "Giờ đến bến",
                },
                {
                    text: "Giờ cấp lên nốt",
                },
                {
                    text: "Giờ cấp lệnh xuất bến",
                },
                {
                    text: "Giờ XB kế hoạch",
                },
            ],
        };
    },
    methods: {
        hiddenDialog() {
            this.$emit("hiddenDialogCaiDat");
        },
        onReorder(e) {
            var visibleRows = e.component.getVisibleRows(),
                toIndex = this.dataSource.indexOf(visibleRows[e.toIndex].data),
                fromIndex = this.dataSource.indexOf(e.itemData);

            this.dataSource.splice(fromIndex, 1);
            this.dataSource.splice(toIndex, 0, e.itemData);
        },
    },
};
</script>

<style scoped>
.frame-box {
    margin: 0 auto;
    width: 100%;
    height: 100%;
}
.frame-box .title {
    width: 100%;
    text-align: center;
}
</style>