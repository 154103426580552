<template>
    <div>
        <div class="frame-box" id="popup_BCHoatDongXeTimeline_ChiTiet">
            <div class="frame-chi-tiet-hoat-dong-xe">
                <div class="row">
                    <div
                        class="font-20 font-medium title"
                        id="header_BCHoatDongXeTimeline_ThongTinChiTiet"
                    >Thông tin chi tiết</div>
                </div>
                <div class="row custom-layout">
                    <div
                        class="xs5 text-flex font-16 pl-2"
                        id="label_BCHoatDongXeTimeline_HinhThucChay_Title"
                    >Mã lệnh vận chuyển:</div>
                    <div
                        class="xs7 text-flex font-16 px-2"
                        id="label_BCHoatDongXeTimeline_HinhThucChay_Value"
                    >{{GUIDNhatTrinhXe}}</div>
                </div>
                <div class="row custom-layout">
                    <div
                        class="xs5 text-flex font-16 pl-2"
                        id="label_BCHoatDongXeTimeline_HinhThucChay_Title"
                    >Hoạt động vận chuyển:</div>
                    <div
                        class="xs7 text-flex font-16 px-2"
                        id="label_BCHoatDongXeTimeline_HinhThucChay_Value"
                    >{{HinhThucChay}}</div>
                </div>
                <div class="row custom-layout">
                    <div
                        class="xs5 text-flex font-16 pl-2"
                        id="label_BCHoatDongXeTimeline_DieuKienLenNot_Title"
                    >Điều kiện xuất bến:</div>
                    <div
                        class="xs7 text-flex font-16 px-2"
                        id="label_BCHoatDongXeTimeline_DieuKienLenNot_Value"
                        :class="!!DuDieuKienLenNot ? 'color-success' : 'color-error'"
                    >
                        <i class="mr-2 mdi mdi-shield-car"></i>
                        {{checkDieuKien(DuDieuKienLenNot)}}
                    </div>
                </div>
                <div class="row">
                    <div
                        class="xs5 text-flex font-16 font-medium"
                        id="header_BCHoatDongXeTimeline_ThongTinGiayTo"
                    >Thông tin giấy tờ</div>
                </div>
                <div class="row custom-layout">
                    <div
                        class="xs5 text-flex font-16 pl-2"
                        id="label_BCHoatDongXeTimeline_HanDangKiem_Title"
                    >Hạn đăng kiểm:</div>
                    <div
                        class="xs7 text-flex font-16 px-2"
                        id="label_BCHoatDongXeTimeline_HanDangKiem_Value"
                        :class="!!TrangThaiHanDangKiem ? 'color-success' : 'color-error'"
                    >
                        {{formatDate(HanDangKiem)}}
                        <i
                            v-if="TrangThaiHanDangKiem == true && !!HanDangKiem"
                            class="ml-2 mdi mdi-checkbox-marked-circle"
                        ></i>
                        <i
                            v-if="TrangThaiHanDangKiem == false && !!HanDangKiem"
                            class="ml-2 mdi mdi-close-circle color-error"
                        ></i>
                    </div>
                </div>
                <div class="row custom-layout">
                    <div
                        class="xs5 text-flex font-16 pl-2"
                        id="label_BCHoatDongXeTimeline_HanBaoHiem_Title"
                    >Hạn bảo hiểm:</div>
                    <div
                        class="xs7 text-flex font-16 px-2"
                        id="label_BCHoatDongXeTimeline_HanBaoHiem_Value"
                        :class="!!TrangThaiHanBaoHiem ? 'color-success' : 'color-error'"
                    >
                        {{formatDate(HanBaoHiem)}}
                        <i
                            v-if="TrangThaiHanBaoHiem == true && !!HanBaoHiem"
                            class="ml-2 mdi mdi-checkbox-marked-circle color-success"
                        ></i>
                        <i
                            v-if="TrangThaiHanBaoHiem == false && !!HanBaoHiem"
                            class="ml-2 mdi mdi-close-circle color-error"
                        ></i>
                    </div>
                </div>
                <div class="row custom-layout">
                    <div
                        class="xs5 text-flex font-16 pl-2"
                        id="label_BCHoatDongXeTimeline_HanPhuHieu_Title"
                    >Hạn phù hiệu:</div>
                    <div
                        class="xs7 text-flex font-16 px-2"
                        id="label_BCHoatDongXeTimeline_HanPhuHieu_Value"
                        :class="!!TrangThaiHanPhuHieu ? 'color-success' : 'color-error'"
                    >
                        {{formatDate(HanPhuHieu)}}
                        <i
                            v-if="TrangThaiHanPhuHieu == true && !!HanPhuHieu"
                            class="ml-2 mdi mdi-checkbox-marked-circle color-success"
                        ></i>
                        <i
                            v-if="TrangThaiHanPhuHieu == false && !!HanPhuHieu"
                            class="ml-2 mdi mdi-close-circle color-error"
                        ></i>
                    </div>
                </div>
                <div class="row">
                    <div
                        class="xs5 text-flex font-16 font-medium"
                        id="header_BCHoatDongXeTimeline_NhanVien"
                    >Nhân viên</div>
                </div>
                <div class="row custom-layout" v-for="(item, i) in DsLaiXe" :key="i">
                    <div
                        class="xs5 text-flex font-16 pl-2"
                        id="label_BCHoatDongXeTimeline_LaiXe_Title"
                    >Lái xe {{i + 1}}:</div>
                    <div
                        class="xs7 text-flex font-16 px-2"
                        id="label_BCHoatDongXeTimeline_LaiXe_Value"
                    >{{item.TenLaiXe}}</div>
                </div>
                <div class="row">
                    <div
                        class="xs12 text-flex font-16 font-medium"
                        id="header_BCHoatDongXeTimeline_BenDi"
                    >Bến đi: {{BenDi}}</div>
                </div>
                <div class="row custom-layout">
                    <div
                        class="xs5 text-flex font-16 pl-2"
                        id="label_BCHoatDongXeTimeline_GioDenBen_Title"
                    >Nhân viên xác nhận đến bến:</div>
                    <div
                        class="xs7 text-flex font-16 px-2"
                        id="label_BCHoatDongXeTimeline_GioDenBen_Value"
                    >{{NV_XacNhanDenBen}}</div>
                </div>
                <div class="row custom-layout">
                    <div
                        class="xs5 text-flex font-16 pl-2"
                        id="label_BCHoatDongXeTimeline_GioDenBen_Title"
                    >Nhân viên cấp phép lên nốt:</div>
                    <div
                        class="xs7 text-flex font-16 px-2"
                        id="label_BCHoatDongXeTimeline_GioDenBen_Value"
                    >{{NV_CapPhepLenNot}}</div>
                </div>
                <div class="row custom-layout">
                    <div
                        class="xs5 text-flex font-16 pl-2"
                        id="label_BCHoatDongXeTimeline_GioDenBen_Title"
                    >Nhân viên cấp lệnh xuất bến:</div>
                    <div
                        class="xs7 text-flex font-16 px-2"
                        id="label_BCHoatDongXeTimeline_GioDenBen_Value"
                    >{{NV_CapLenhXuatBen}}</div>
                </div>
                <div class="row custom-layout">
                    <div
                        class="xs5 text-flex font-16 pl-2"
                        id="label_BCHoatDongXeTimeline_GioDenBen_Title"
                    >Giờ vào bến:</div>
                    <div
                        class="xs7 text-flex font-16 px-2"
                        id="label_BCHoatDongXeTimeline_GioDenBen_Value"
                    >{{formatDateTime(GioDenBenDi)}}</div>
                </div>
                <div class="row custom-layout">
                    <div
                        class="xs5 text-flex font-16 pl-2"
                        id="label_BCHoatDongXeTimeline_GioDenBen_Title"
                    >Giờ cấp lên nốt:</div>
                    <div
                        class="xs7 text-flex font-16 px-2"
                        id="label_BCHoatDongXeTimeline_GioDenBen_Value"
                    >{{formatDateTime(GioCapLenNot)}}</div>
                </div>
                <div class="row custom-layout">
                    <div
                        class="xs5 text-flex font-16 pl-2"
                        id="label_BCHoatDongXeTimeline_GioCapLenhXuatBen_Title"
                    >Giờ cấp lệnh xuất bến:</div>
                    <div
                        class="xs7 text-flex font-16 px-2"
                        id="label_BCHoatDongXeTimeline_GioCapLenhXuatBen_Value"
                    >{{formatDateTime(GioCapLenhXuatBen)}}</div>
                </div>
                <div class="row custom-layout">
                    <div
                        class="xs5 text-flex font-16 pl-2"
                        id="label_BCHoatDongXeTimeline_GioXuatBenKeHoach_Title"
                    >Giờ XB kế hoạch:</div>
                    <div
                        class="xs7 text-flex font-16 px-2"
                        id="label_BCHoatDongXeTimeline_GioXuatBenKeHoach_Value"
                    >{{formatDateTime(GioXBKeHoach)}}</div>
                </div>
                <div class="row custom-layout">
                    <div
                        class="xs5 text-flex font-16 pl-2"
                        id="label_BCHoatDongXeTimeline_GioXuatBenThucTe_Title"
                    >Giờ XB thực tế:</div>
                    <div
                        class="xs7 text-flex font-16 px-2"
                        id="label_BCHoatDongXeTimeline_GioXuatBenThucTe_Value"
                    >{{formatDateTime(GioXBThucTe)}}</div>
                </div>
                <div class="row custom-layout">
                    <div
                        class="xs5 text-flex font-16 pl-2"
                        id="label_BCHoatDongXeTimeline_GioXuatBenThucTe_Title"
                    >Giờ truyền tải dữ liệu:</div>
                    <div
                        class="xs7 text-flex font-16 px-2"
                        id="label_BCHoatDongXeTimeline_GioXuatBenThucTe_Value"
                    >{{formatDateTime(GioTruyenTaiDuLieuBenDi)}}</div>
                </div>
                <div class="row custom-layout">
                    <div
                        class="xs5 text-flex font-16 pl-2"
                        id="label_BCHoatDongXeTimeline_GioXuatBenThucTe_Title"
                    >Độ lệch giờ truyền tải:</div>
                    <div
                        class="xs7 text-flex font-16 px-2"
                        id="label_BCHoatDongXeTimeline_GioXuatBenThucTe_Value"
                        :class="!!checkDoLechGioTruyenTai(DoLechGioTruyenTaiXuatBen) ? 'color-error' : 'color-success'"
                    >{{convertDoLechGioTruyenTai(DoLechGioTruyenTaiXuatBen)}}</div>
                </div>
                <div class="row custom-layout">
                    <div
                        class="xs5 text-flex font-16 pl-2"
                        id="label_BCHoatDongXeTimeline_SoKhach_Title"
                    >Số khách:</div>
                    <div
                        class="xs7 text-flex font-16 px-2"
                        id="label_BCHoatDongXeTimeline_SoKhach_Value"
                    >{{SoKhachDi}}</div>
                </div>
                <div class="row">
                    <div
                        class="xs12 text-flex font-16 font-medium"
                        id="header_BCHoatDongXeTimeline_BenDen"
                    >Bến đến: {{BenDen}}</div>
                </div>
                <div class="row custom-layout">
                    <div
                        class="xs5 text-flex font-16 pl-2"
                        id="label_BCHoatDongXeTimeline_GioDenBen_Title"
                    >Giờ đến bến:</div>
                    <div
                        class="xs7 text-flex font-16 px-2"
                        id="label_BCHoatDongXeTimeline_GioDenBen_Value"
                    >{{formatDateTime(GioDenBenDen)}}</div>
                </div>
                <div class="row custom-layout">
                    <div
                        class="xs5 text-flex font-16 pl-2"
                        id="label_BCHoatDongXeTimeline_GioXacNhanTraKhac_Title"
                    >Giờ xác nhận trả khách:</div>
                    <div
                        class="xs7 text-flex font-16 px-2"
                        id="label_BCHoatDongXeTimeline_GioXacNhanTraKhac_Value"
                    >{{formatDateTime(GioXacNhanTraKhach)}}</div>
                </div>
                <div class="row custom-layout">
                    <div
                        class="xs5 text-flex font-16 pl-2"
                        id="label_BCHoatDongXeTimeline_GioXuatBenThucTe_Title"
                    >Giờ truyền tải dữ liệu:</div>
                    <div
                        class="xs7 text-flex font-16 px-2"
                        id="label_BCHoatDongXeTimeline_GioXuatBenThucTe_Value"
                    >{{formatDateTime(GioTruyenTaiDuLieuBenDen)}}</div>
                </div>
                <div class="row custom-layout">
                    <div
                        class="xs5 text-flex font-16 pl-2"
                        id="label_BCHoatDongXeTimeline_GioXuatBenThucTe_Title"
                    >Độ lệch giờ truyền tải:</div>
                    <div
                        class="xs7 text-flex font-16 px-2"
                        id="label_BCHoatDongXeTimeline_GioXuatBenThucTe_Value"
                        :class="!!checkDoLechGioTruyenTai(DoLechGioTruyenTaiDenBen) ? 'color-error' : 'color-success'"
                    >{{convertDoLechGioTruyenTai(DoLechGioTruyenTaiDenBen)}}</div>
                </div>
                <div class="row custom-layout">
                    <div
                        class="xs5 text-flex font-16 pl-2"
                        id="label_BCHoatDongXeTimeline_SoKhachDenBen_Title"
                    >Số khách:</div>
                    <div
                        class="xs7 text-flex font-16 px-2"
                        id="label_BCHoatDongXeTimeline_SoKhachDenBen_Value"
                    >{{SoKhachDen}}</div>
                </div>
                <div class="row">
                    <div
                        class="xs5 text-flex font-16 font-medium"
                        id="header_BCHoatDongXeTimeline_GSHT"
                    >Giám sát hành trình</div>
                </div>
                <div class="row custom-layout">
                    <div
                        class="xs5 text-flex font-16 pl-2"
                        id="label_BCHoatDongXeTimeline_TocDoTrungBinh_Title"
                    >Tốc độ trung bình:</div>
                    <div
                        class="xs7 text-flex font-16 px-2"
                        id="label_BCHoatDongXeTimeline_TocDoTrungBinh_Value"
                    >{{TocDoTrungBinh}}</div>
                </div>
                <div class="row custom-layout">
                    <div
                        class="xs5 text-flex font-16 pl-2"
                        id="label_BCHoatDongXeTimeline_TocDoCaoNhat_Title"
                    >Tốc độ cao nhất:</div>
                    <div
                        class="xs7 text-flex font-16 px-2"
                        id="label_BCHoatDongXeTimeline_TocDoCaoNhat_Value"
                    >{{TocDoCaoNhat}}</div>
                </div>
                <div class="row">
                    <div
                        class="xs12 text-flex font-16 font-medium"
                        id="header_BCHoatDongXeTimeline_GhiChu"
                    >Ghi chú</div>
                </div>
                <div class="row">
                    <div
                        class="xs12 text-flex font-16 px-2"
                        id="label_BCHoatDongXeTimeline_GhiChu_Value"
                    >{{GhiChu}}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from "moment";
export default {
    props: {
        ChiTietTuyen: { type: Object, default: {} },
        NgayGioHeThong: { type: String, default: null },
    },
    data() {
        return {
            HinhThucChay: null,
            DuDieuKienLenNot: false,
            HanDangKiem: null,
            HanBaoHiem: null,
            HanPhuHieu: null,
            TrangThaiHanDangKiem: false,
            TrangThaiHanBaoHiem: false,
            TrangThaiHanPhuHieu: false,
            DsLaiXe: null,
            NV_XacNhanDenBen: null,
            NV_CapPhepLenNot: null,
            NV_CapLenhXuatBen: null,
            GioDenBenDi: null,
            GioCapLenNot: null,
            GioCapLenhXuatBen: null,
            GioXBKeHoach: null,
            GioXBThucTe: null,
            GioTruyenTaiDuLieuBenDi: null,
            GioTruyenTaiDuLieuBenDen: null,
            DoLechGioTruyenTaiXuatBen: null,
            DoLechGioTruyenTaiDenBen: null,
            SoKhachDi: null,
            GioDenBenDen: null,
            GioXacNhanTraKhach: null,
            SoKhachDen: null,
            TocDoTrungBinh: null,
            TocDoCaoNhat: null,
            GhiChu: null,
            GUIDNhatTrinhXe: null,
            BenDi: null,
            BenDen: null,
        };
    },
    watch: {
        ChiTietTuyen: {
            immediate: true,
            handler: function (val) {
                // console.log("valabcd", val);
                this.HinhThucChay = val.HinhThucChay;
                this.GUIDNhatTrinhXe = val.GUIDNhatTrinhXe;
                this.DuDieuKienLenNot = val.DieuKienLenNot;
                this.HanDangKiem = val.HanDangKiem;
                this.HanBaoHiem = val.HanBaoHiem;
                this.HanPhuHieu = val.HanPhuHieu;
                this.TrangThaiHanDangKiem = val.TrangThaiHanDangKiem;
                this.TrangThaiHanBaoHiem = val.TrangThaiHanBaoHiem;
                this.TrangThaiHanPhuHieu = val.TrangThaiHanPhuHieu;
                if (!!val.LaiXe && val.LaiXe.length > 0) {
                    this.DsLaiXe = val.LaiXe;
                } else {
                    this.DsLaiXe = [
                        {
                            TenLaiXe: null,
                        },
                    ];
                }
                let TenBenDi = val.TenBenDi || "";
                let MaSoDi = "";
                let MaBenDi = "";
                if (!!val.MaSoDi && !!val.MaBenDi) {
                    MaSoDi = " (" + val.MaSoDi;
                    MaBenDi = "." + val.MaBenDi + ")";
                }
                this.BenDi = TenBenDi + MaSoDi + MaBenDi;

                let TenBenDen = val.TenBenDen || "";
                let MaSoDen = "";
                let MaBenDen = "";
                if (!!val.MaSoDen && !!val.MaBenDen) {
                    MaSoDen = " (" + val.MaSoDen;
                    MaBenDen = "." + val.MaBenDen + ")";
                }
                this.BenDen = TenBenDen + MaSoDen + MaBenDen;
                this.NV_XacNhanDenBen = val.NV_XacNhanDenBen;
                this.NV_CapPhepLenNot = val.NV_CapPhepLenNot;
                this.NV_CapLenhXuatBen = val.NV_CapLenhXuatBen;
                this.GioDenBenDi = val.BenDi_GioDenBen;
                this.GioCapLenNot = val.BenDi_GioCapLenNot;
                this.GioCapLenhXuatBen = val.BenDi_GioCapLenhXuatBen;
                this.GioXBKeHoach = val.BenDi_GioXBKeHoach;
                this.GioXBThucTe = val.BenDi_GioXBThucTe;
                this.GioTruyenTaiDuLieuBenDi = val.ThoiGianTruyenTaiXuatBen;
                this.GioTruyenTaiDuLieuBenDen = val.ThoiGianTruyenTaiDenBen;
                this.DoLechGioTruyenTaiDenBen = val.DoTreTruyenTaiDenBen;
                this.DoLechGioTruyenTaiXuatBen = val.DoTreTruyenTaiXuatBen;
                this.SoKhachDi = val.BenDi_SoKhach;
                this.GioDenBenDen = val.BenDen_GioDenBen;
                this.GioXacNhanTraKhach = val.BenDen_GioXacNhanTraKhach;
                this.SoKhachDen = val.BenDen_SoKhach;
                this.TocDoTrungBinh = val.GSHT_TocDoTrungBinh;
                this.TocDoCaoNhat = val.GSHT_TocDoCaoNhat;
                this.GhiChu = val.GhiChu;
            },
            deep: true,
        },
    },
    methods: {
        checkDieuKien(check) {
            if (!!check) {
                return "Đủ điều kiện";
            } else {
                return "Không đủ điều kiện";
            }
        },
        formatDateTime(date) {
            if (date) {
                return moment(date).format("HH:mm:ss DD/MM/YYYY");
            } else {
                return "";
            }
        },
        formatDate(date) {
            if (date) {
                return moment(date).format("DD/MM/YYYY");
            } else {
                return "";
            }
        },
        checkDoLechGioTruyenTai(Gio) {
            if (!!Gio) {
                if (Gio > 180) {
                    return true;
                } else {
                    return false;
                }
            } else {
                return false;
            }
        },
        convertDoLechGioTruyenTai(Gio) {
            if (!!Gio) {
                let phut = Math.floor(Gio / 60),
                    giay;
                if (Gio < 0) {
                    giay = (Gio - Gio - Gio) % 60;
                } else {
                    giay = Gio % 60;
                }
                return phut + " phút " + giay + " giây";
            }
        },
    },
};
</script>

<style scoped>
.frame-box {
    margin: 0 auto;
    width: 100%;
    height: 100%;
    /* height: calc(100vh - 48px);
    overflow-y: scroll;
    overflow-x: hidden; */
}
.frame-box .title {
    width: 100%;
    text-align: center;
}
.text-flex {
    max-height: 48px !important;
    display: flex;
    align-items: center;
}
</style>