<template>
    <div>
        <DxToolbar :height="56" class="toolbar">
            <DxItem location="before" locate-in-menu="never">
                <template #default>
                    <router :breadcrumbsItems="breadcrumbsItems" />
                </template>
            </DxItem>
        </DxToolbar>
        <div class="container">
            <div class="row">
                <div class="xs10">
                    <div
                        class="row align-center"
                        id="label_BCHoatDongXeTimeline_BienKiemSoat"
                    >
                        <span class="font-24 font-bold mr-3"
                            >Biển kiểm soát:</span
                        >
                        <DxSelectBox
                            v-model="BienKiemSoat"
                            :dataSource="{
                                store: data_from_api.DsBienKiemSoat,
                                paginate: true,
                            }"
                            displayExpr="BienKiemSoat"
                            valueExpr="IdXe"
                            :searchEnabled="true"
                            stylingMode="outlined"
                            placeholder
                            id="dropdown_BCHoatDongXeTimeline_BienKiemSoat"
                            width="200"
                            height="36"
                        ></DxSelectBox>
                        <div class="fill-height ml-3">
                            <DxDateBox
                                v-model="NgayLoc"
                                stylingMode="outlined"
                                displayFormat="dd/MM/yyyy"
                                dropDownButtonTemplate="customIcon"
                                height="36"
                                width="200"
                                :onContentReady="onDateBoxContentReady"
                                :onPaste="onPaste"
                                id="datebox_BCHoatDongXeTimeline_ThoiGian"
                                :useMaskBehavior="true"
                            >
                                <template #customIcon="{}">
                                    <DxButton icon="mdi mdi-calendar" />
                                </template>
                            </DxDateBox>

                            <!-- <DxNumberBox :onKeyDown="keyDown" :onPaste="onPaste" /> -->
                        </div>
                        <div class="fill-height ml-3">
                            <DxButton
                                id="button_BCHoatDongXeTimeline_Loc"
                                icon="mdi mdi-magnify"
                                styling-mode="outlined"
                                height="36"
                                type="normal"
                                class="custom-icon"
                                @click="LocDuLieu()"
                            />
                        </div>
                    </div>
                    <div
                        class="row font-20 font-regular mt-2"
                        id="label_BCHoatDongXeTimeline_DonViVanTai"
                    >
                        {{ TenDVVT }}
                    </div>
                    <div
                        class="row font-16 font-medium mt-1"
                        id="label_BCHoatDongXeTimeline_Soghe_Giuong"
                    >
                        Số ghế | số giường: {{ SoGheSoGiuong }}
                    </div>
                </div>
                <div class="xs2">
                    <div class="row justify-end">
                        <div class="fill-height ml-4">
                            <DxButton
                                icon="mdi mdi-format-list-checkbox"
                                id="button_BCHoatDongXeTimeline_CaiDat"
                                @click="XemDangDanhSach()"
                            />
                            <DxButton
                                icon="mdi mdi-chart-timeline-variant"
                                id="button_BCHoatDongXeTimeline_CaiDat"
                                class="color-primary"
                            />
                            <DxButton
                                icon="mdi mdi-cog"
                                id="button_BCHoatDongXeTimeline_CaiDat"
                                @click="openCaiDatHienThi = true"
                                :disabled="true"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <BaoCaoHoatDongCuaXe
            :data_from_api="data_from_api"
            :Loadding="Loadding"
            :BienKiemSoat="BienKiemSoat"
            :NgayGioHeThong="NgayGioHeThong"
            :isLoc="isLoc"
            :isScroll="isScroll"
            :NgayLoc="NgayLoc"
            ref="comBaoCaoHoatDongCuaXe"
            :openCaiDatHienThi="openCaiDatHienThi"
            @hiddenDialogCaiDat="hiddenDialogCaiDat"
            @LayThemDuLieu="LayThemDuLieu"
        />

        <!-- Loadding -->
        <!-- <LoaddingVue :Model="Loadding" /> -->
    </div>
</template>
<script>
import BaoCaoHoatDongCuaXe from "@/components/BaoCaoThongKe/BaoCaoHoatDongCuaXe/BaoCaoHoatDongCuaXe";
import router from "@/components/_Common/Components/router";
import LoaddingVue from "@/components/_Common/Components/Loadding.vue";
import moment from "moment";
import DxToolbar, { DxItem } from "devextreme-vue/toolbar";
import { DxSelectBox, DxDateBox, DxButton } from "devextreme-vue";

export default {
    // Permission: $PermText("Timeline", "BCHoatDongXe"),
    layout: "application",
    components: {
        BaoCaoHoatDongCuaXe,
        router,
        LoaddingVue,
        DxToolbar,
        DxItem,
        DxSelectBox,
        DxDateBox,
        DxButton,
    },
    data() {
        return {
            url: this.$i18n.t("url.ChiTietBaoCaoHoatDongXeList"),
            url_NgayGioHeThong: this.$i18n.t("url.NgayGioHeThong"),
            url_DsBienKiemSoat: this.$i18n.t(
                "url.ChiTietBCHoatDongXeDanhSachBienKiemSoatXe"
            ),
            openCaiDatHienThi: false,
            BienKiemSoat: null,
            NgayHoatDong: null,
            TenDVVT: null,
            SoGheSoGiuong: null,
            openDateBox: false,
            Loadding: false,
            NgayLoc: null,
            isLoc: false,
            isScroll: false,
            data_from_api: {
                Data: null,
                DsBienKiemSoat: [],
            },
            NgayGioHeThong: null,
            breadcrumbsItems: [
                {
                    id: "breadcrums_BCHoatDongXeTimeline_BaoCaoThongKe",
                    text: "Báo cáo - thống kê",
                    disabled: true,
                    to: "",
                },
                {
                    id: "breadcrums_BCHoatDongXeTimeline_BaoCaoHoatDongXe",
                    text: "Báo cáo hoạt động của xe",
                    disabled: false,
                    to: "/Bao-Cao-Thong-Ke/Bao-Cao-Hoat-Dong-Cua-Xe/",
                },
                {
                    id: "breadcrums_BCHoatDongXeTimeline_BaoCaoHoatDongXe",
                    text: "Chi tiết thông tin",
                    disabled: true,
                    to: "",
                },
            ],
        };
    },
    methods: {
        getRandomColor() {
            var letters = "0123456789ABCDEF";
            var color = "#";
            for (var i = 0; i < 6; i++) {
                color += letters[Math.floor(Math.random() * 16)];
            }
            return color;
        },
        // Hàm lọc dữ liệu
        async LocDuLieu(firstTime) {
            this.$startLoading;
            this.isLoc = true;
            this.isScroll = false;

            if (!!firstTime) {
                // Lấy giờ hệ thống
                let NgayGioHeThong = await this.$Core.Api.BaoCaoBenXe(
                    this.url_NgayGioHeThong
                ).Get();
                this.NgayGioHeThong = NgayGioHeThong.Data.Data;
                if (!!this.NgayHoatDong) {
                    this.NgayLoc = this.NgayHoatDong;
                } else {
                    this.NgayLoc = NgayGioHeThong.Data.Data;
                }
                // Lấy ds biển kiểm soát
                let DsBienKiemSoat = await this.$Core.Api.BaoCaoBenXe(
                    this.url_DsBienKiemSoat
                ).Get();
                this.data_from_api.DsBienKiemSoat = DsBienKiemSoat.Data.Data;
            }

            let data = {
                tuNgay: this.$Helper.ConvertToTimeTicks(this.NgayLoc),
                idXe: this.BienKiemSoat,
                down: true,
                first: true,
                limitDay: 10,
            };
            // Lấy dữ liệu và gán
            let rs = await this.$Core.Api.BaoCaoBenXe(this.url, data).Get();
            this.data_from_api.Data = rs.Data.Data;
            this.TenDVVT = null;
            this.SoGheSoGiuong = "0 | 0";

            // Nếu có data thì gán
            if (!!this.data_from_api.Data) {
                // this.BienKiemSoat = rs.Data.Data.Data.BienKiemSoat || null;
                this.TenDVVT = rs.Data.Data.DonViVanTai || null;
                this.SoGheSoGiuong =
                    rs.Data.Data.SoGhe + " | " + rs.Data.Data.SoGiuong || 0;

                // Gán màu cho nút
                if (this.data_from_api.Data.ChiTietHoatDongNgay.length > 0) {
                    this.data_from_api.Data.ChiTietHoatDongNgay.forEach(
                        (e, i) => {
                            this.data_from_api.Data.ChiTietHoatDongNgay[
                                i
                            ].color = this.getRandomColor();
                        }
                    );
                }

                // Lấy cho đủ 10 dòng dữ liệu
                if (this.data_from_api.Data.ChiTietHoatDongNgay.length < 10) {
                    this.LayThemDuLieu(
                        this.BienKiemSoat,
                        moment(this.NgayLoc).format("DD/MM/YYYY"),
                        false,
                        10 - this.data_from_api.Data.ChiTietHoatDongNgay.length,
                        this.isLoc
                    );
                } else {
                    setTimeout(() => {
                        this.isScroll = true;
                    }, 200);
                    setTimeout(() => {
                        this.isLoc = false;
                        this.isScroll = false;
                    }, 1500);
                    this.$stopLoading;
                }
            } else {
                this.$stopLoading;
            }
        },

        // Hàm lấy dữ liệu ban đầu
        async getData(firstTime) {
            this.$startLoading;
            this.data_from_api.Data = null;

            if (!!firstTime) {
                // Lấy giờ hệ thống
                let NgayGioHeThong = await this.$Core.Api.BaoCaoBenXe(
                    this.url_NgayGioHeThong
                ).Get();
                this.NgayGioHeThong = NgayGioHeThong.Data.Data;
                if (!!this.NgayHoatDong) {
                    this.NgayLoc = this.NgayHoatDong;
                } else {
                    this.NgayLoc = NgayGioHeThong.Data.Data;
                }
                // Lấy ds biển kiểm soát
                let DsBienKiemSoat = await this.$Core.Api.BaoCaoBenXe(
                    this.url_DsBienKiemSoat
                ).Get();
                this.data_from_api.DsBienKiemSoat = DsBienKiemSoat.Data.Data;
            } else {
                let data = {
                    tuNgay: this.$Helper.ConvertToTimeTicks(this.NgayLoc),
                    idXe: this.BienKiemSoat,
                    down: true,
                    first: true,
                    limitDay: 10,
                };
                // Lấy dữ liệu và gán
                let rs = await this.$Core.Api.BaoCaoBenXe(this.url, data).Get();
                this.data_from_api.Data = rs.Data.Data;
                this.TenDVVT = null;
                this.SoGheSoGiuong = "0 | 0";
                // Nếu có data thì gán
                if (!!this.data_from_api.Data) {
                    // this.BienKiemSoat = rs.Data.Data.Data.BienKiemSoat || null;
                    this.TenDVVT = rs.Data.Data.DonViVanTai || null;
                    this.SoGheSoGiuong =
                        rs.Data.Data.SoGhe + " | " + rs.Data.Data.SoGiuong || 0;

                    // Gán màu cho nút
                    if (
                        this.data_from_api.Data.ChiTietHoatDongNgay.length > 0
                    ) {
                        this.data_from_api.Data.ChiTietHoatDongNgay.forEach(
                            (e, i) => {
                                this.data_from_api.Data.ChiTietHoatDongNgay[
                                    i
                                ].color = this.getRandomColor();
                            }
                        );
                    }
                }
            }
            this.NgayHoatDong = null;
            this.$stopLoading;
        },

        // Hàm lấy thêm dữ liệu
        async LayThemDuLieu(BienSo, Ngay, Xuong, Limit, isLoc) {
            this.$startLoading;
            // Gán dữ liệu cũ
            let arrayOld = this.data_from_api.Data.ChiTietHoatDongNgay;
            let data = {
                tuNgay: this.$Helper.ConvertToTimeTicks(
                    moment(Ngay, "DD/MM/YYYY").format("YYYY-MM-DD")
                ),
                idXe: BienSo,
                down: Xuong || false,
                first: false,
                limitDay: Limit || 10,
            };
            // Lấy dữ liệu và gán
            let rs = await this.$Core.Api.BaoCaoBenXe(this.url, data).Get();
            if (!!rs.Data.Data) {
                // Gán dữ liệu mới
                let arrayNew = rs.Data.Data.ChiTietHoatDongNgay;

                // Gán màu cho nút
                if (arrayNew.length > 0) {
                    arrayNew.forEach((e, i) => {
                        arrayNew[i].color = this.getRandomColor();
                    });
                }

                // Ghép 2 dữ liệu lại
                if (!!Xuong) {
                    this.data_from_api.Data.ChiTietHoatDongNgay =
                        arrayOld.concat(arrayNew);
                } else if (!Xuong) {
                    this.data_from_api.Data.ChiTietHoatDongNgay =
                        arrayNew.concat(arrayOld);
                } else {
                    this.data_from_api.Data.ChiTietHoatDongNgay = arrayNew;
                }
            }
            this.$stopLoading;
            if (!!isLoc) {
                setTimeout(() => {
                    this.isScroll = true;
                }, 300);
                setTimeout(() => {
                    this.isLoc = false;
                    this.isScroll = false;
                }, 1500);
            } else {
                this.isScroll = false;
            }
        },
        hiddenDialogCaiDat() {
            this.openCaiDatHienThi = false;
        },
        onDateBoxContentReady(e) {
            // console.log("onDateBoxContentReady -> e", e.component.option());
            // e.component.option("inputAttr", { readonly: true });
        },
        keyDown(e) {
            const str = event.key || String.fromCharCode(event.which);
            if (!/^[0-9]*$/.test(str)) {
                e.event.preventDefault();
            }
        },
        onPaste(e) {
            e.event.preventDefault();
        },
        XemDangDanhSach() {
            localStorage.setItem("BienKiemSoat", this.BienKiemSoat);
            localStorage.setItem("NgayHoatDong", this.NgayLoc);
            this.$router.push(
                "/Bao-Cao-Thong-Ke/Bao-Cao-Hoat-Dong-Cua-Xe/Danh-Sach-Chi-Tiet"
            );
        },
    },
    created() {
        this.BienKiemSoat = parseInt(localStorage.getItem("BienKiemSoat"));
        this.NgayHoatDong = localStorage.getItem("NgayHoatDong");
        setTimeout(() => {
            if (!!this.NgayHoatDong) {
                this.LocDuLieu(true);
            } else {
                this.getData(true);
            }
        }, 100);
        localStorage.removeItem("BienKiemSoat");
        localStorage.removeItem("NgayHoatDong");
    },
};
</script>


<style scoped>
.custom-icon {
    border-radius: 4px !important;
}
>>> .color-primary i {
    color: #006fff;
}
</style>