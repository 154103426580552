<template>
    <div class="frame-content-box">
        <div class="frame-timeline">
            <!-- <VueAutoVirtualScrollList
                :totalHeight="800"
                :defaultHeight="80"
                :extraItems="1"
                class="frame-layout"
            >-->
            <div
                class="frame-layout"
                id="frame-layout"
                @scroll="onScroll"
                v-if="!!dataXe && dataXe.length > 0"
            >
                <div
                    class="frame-content"
                    v-for="(item, index) in dataXe"
                    :key="index"
                    :id="GetIdNgay(item.Ngay)"
                >
                    <div
                        class="frame-ngay-hoat-dong"
                        :id="'label_BCHoatDongXeTimeline_' + item.Ngay"
                    >
                        {{ item.Ngay }}
                    </div>
                    <div class="frame-thong-tin-hoat-dong">
                        <!-- <div> -->
                        <div
                            class="content-thong-tin row justify-space-between"
                            v-for="(tuyen, i) in item.ChiTiet"
                            :key="i"
                            @click="XemChiTiet(tuyen)"
                            :id="
                                'div_BCHoatDongXeTimeline_NhatTrinhXe_' +
                                tuyen.Id
                            "
                        >
                            <div class="arrow"></div>
                            <div class="xs8">
                                <div class="dot-timeline">
                                    <i
                                        class="mdi mdi-brightness-1"
                                        :style="'color:' + item.color"
                                    ></i>
                                </div>
                                <div
                                    class="row ten-tuyen"
                                    :id="
                                        'label_BCHoatDongXeTimeline_TenTuyen_' +
                                        tuyen.Id
                                    "
                                    title="Tuyến xe chạy"
                                >
                                    <i class="mr-2 mdi mdi-road-variant"></i>
                                    {{ tuyen.Tuyen }}
                                </div>
                                <div
                                    class="row row-label"
                                    :id="
                                        'label_BCHoatDongXeTimeline_ThongTinBenDi_' +
                                        tuyen.Id
                                    "
                                    title="Bến đi - Giờ xuất bến"
                                >
                                    <i class="mdi mdi-bus-side mr-2"></i>
                                    Bến đi:
                                    {{ tuyen.TenBenDi }}
                                    <span v-if="!!tuyen.BenDi_GioXBThucTe"
                                        >&nbsp-&nbsp</span
                                    >
                                    {{
                                        formatDateTime(tuyen.BenDi_GioXBThucTe)
                                    }}
                                </div>
                                <div
                                    class="row row-label"
                                    :id="
                                        'label_BCHoatDongXeTimeline_ThongTinBenDen_' +
                                        tuyen.Id
                                    "
                                    title="Bến đến - Giờ đến bến"
                                >
                                    <i class="mdi mdi-bus-stop mr-2"></i>
                                    Bến đến:
                                    {{ tuyen.TenBenDen }}
                                    <span v-if="!!tuyen.BenDen_GioDenBen"
                                        >&nbsp-&nbsp</span
                                    >
                                    {{ formatDateTime(tuyen.BenDen_GioDenBen) }}
                                </div>
                                <div
                                    class="row row-label"
                                    :id="
                                        'label_BCHoatDongXeTimeline_HinhThucChay_' +
                                        tuyen.Id
                                    "
                                    title="Lái xe"
                                >
                                    <i
                                        class="mdi mdi-account-multiple mr-2"
                                    ></i>
                                    Lái xe:
                                    {{ ShowLaiXe(tuyen.LaiXe) }}
                                </div>
                                <div
                                    class="row row-label"
                                    :id="
                                        'label_BCHoatDongXeTimeline_HinhThucChay_' +
                                        tuyen.Id
                                    "
                                    title="Hoạt động vận chuyển"
                                >
                                    <i
                                        class="mdi mdi-truck-fast-outline mr-2"
                                    ></i>
                                    Hoạt động vận chuyển:
                                    {{ tuyen.HinhThucChay }}
                                </div>
                                <div
                                    class="row row-label"
                                    :id="
                                        'label_BCHoatDongXeTimeline_DieuKienLenNot_' +
                                        tuyen.Id
                                    "
                                    :class="
                                        !!tuyen.DieuKienLenNot
                                            ? 'color-success'
                                            : 'color-error'
                                    "
                                    title="Điều kiện xuất bến"
                                >
                                    <i class="mr-2 mdi mdi-shield-car"></i>
                                    {{ checkDieuKien(tuyen.DieuKienLenNot) }}
                                </div>
                            </div>
                            <div class="xs4">
                                <div class="row justify-end">
                                    <div class="qr-code-img">
                                        <qr-code
                                            :text="tuyen.GUIDNhatTrinhXe"
                                            :size="148"
                                            color="#000"
                                            bg-color="#fff"
                                            error-level="H"
                                        ></qr-code>
                                    </div>
                                </div>
                                <div class="row justify-end">
                                    <div class="qr-code-label">
                                        Mã lệnh vận chuyển
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- </div> -->
                        <!-- <div class="chi-tiet-thong-tin"></div> -->
                    </div>
                </div>
            </div>
            <!-- </VueAutoVirtualScrollList> -->
        </div>

        <!-- Popup chi tiết hđ của xe -->
        <DxPopup
            v-model:visible="openDetail"
            :drag-enabled="false"
            :close-on-outside-click="true"
            :show-title="false"
            :position="position"
            :width="592"
            height="100vh"
            :animation="animation"
        >
            <template #content>
                <DxScrollView width="100%" height="100%">
                    <PopupChiTietHoatDongVue
                        :NgayGioHeThong="NgayGioHeThong"
                        :ChiTietTuyen="ChiTietTuyen"
                    />
                </DxScrollView>
            </template>
        </DxPopup>

        <!-- Popup cài đặt hiển thị -->
        <DxPopup
            :visible="openCaiDatHienThi"
            :drag-enabled="false"
            :close-on-outside-click="false"
            :show-title="false"
            :width="608"
            height="auto"
        >
            <template #content>
                <DxScrollView width="100%" height="100%">
                    <PopupCaiDatHienThi
                        @hiddenDialogCaiDat="hiddenDialogCaiDat"
                    />
                </DxScrollView>
            </template>
        </DxPopup>
    </div>
</template>

<script>
import PopupChiTietHoatDongVue from "./PopupChiTietHoatDong.vue";
import PopupCaiDatHienThi from "./PopupCaiDatHienThi";
import moment from "moment";
import { DxPopup } from "devextreme-vue";
import $ from "jquery";
import DxScrollView from "devextreme-vue/scroll-view";
import QrCode from "vue-qrcode-component";
export default {
    components: {
        PopupChiTietHoatDongVue,
        PopupCaiDatHienThi,
        DxPopup,
        DxScrollView,
        QrCode,
    },
    props: {
        openCaiDatHienThi: { type: Boolean, default: false },
        Loadding: { type: Boolean, default: false },
        isLoc: { type: Boolean, default: false },
        isScroll: { type: Boolean, default: false },
        NgayLoc: { type: String, default: null },
        data_from_api: { type: Object, default: {} },
        BienKiemSoat: { type: Number, default: null },
        NgayGioHeThong: { type: String, default: null },
    },
    data() {
        return {
            openDetail: false,
            idOpenOld: null,
            idNgayFocus: null,
            ChiTietTuyen: null,
            animation: {
                hide: {
                    type: "slide",
                    duration: 400,
                    from: {
                        position: { my: "right", at: "right", of: window },
                    },
                    to: { position: { my: "left", at: "right", of: window } },
                },
                show: {
                    type: "slide",
                    duration: 400,
                    from: { position: { my: "left", at: "right", of: window } },
                    to: { position: { my: "left", at: "right", of: window } },
                },
            },
            position: { my: "right", at: "right", of: window },
            dataXe: [],
        };
    },
    watch: {
        data_from_api: {
            immediate: true,
            handler: function (val) {
                this.dataXe = [];
                if (!!val.Data) {
                    this.dataXe = val.Data.ChiTietHoatDongNgay;
                }
            },
            deep: true,
        },
        isLoc: {
            handler: function (val) {
                if (!!val) {
                    this.dataXe = [];
                }
            },
            deep: true,
        },
        Loadding: {
            handler: function (val) {
                // Scroll khi load lên
                if (!val && !!this.idNgayFocus) {
                    setTimeout(() => {
                        $("#frame-layout").animate(
                            {
                                scrollTop:
                                    $("#" + this.idNgayFocus).offset().top -
                                    250,
                            },
                            0
                        );
                    }, 100);
                }
                if (!!val && !!this.NgayLoc && !this.isScroll) {
                    let NgayLoc = moment(this.NgayLoc).format("DD/MM/YYYY");
                    $(
                        "#" + this.GetIdNgay(NgayLoc) + " .frame-ngay-hoat-dong"
                    ).css("color", "rgba(0,0,0,.87)");
                }
            },
            deep: true,
        },
        isScroll: {
            immediate: true,
            handler: function (val) {
                // Scroll khi lọc
                if (!!val && !!this.NgayLoc && !this.Loadding) {
                    let height = $("#frame-layout").height();
                    let NgayLoc = moment(this.NgayLoc).format("DD/MM/YYYY");

                    // Kiểm tra có tồn tại ngày vừa lọc không
                    if (!!document.getElementById(this.GetIdNgay(NgayLoc))) {
                        // Bôi màu cho ngày lọc
                        $(
                            "#" +
                                this.GetIdNgay(NgayLoc) +
                                " .frame-ngay-hoat-dong"
                        ).css("color", "#13b000");

                        // Scroll đến ngày lọc
                        if (
                            $("#" + this.GetIdNgay(NgayLoc)).offset().top >
                                height ||
                            $("#" + this.GetIdNgay(NgayLoc)).offset().top <
                                $("#" + this.GetIdNgay(NgayLoc)).height()
                        ) {
                            $("#frame-layout").animate(
                                {
                                    scrollTop:
                                        $(
                                            "#" + this.GetIdNgay(NgayLoc)
                                        ).offset().top - 250,
                                },
                                "slow"
                            );
                        }
                    }
                }
            },
            deep: true,
        },
    },
    methods: {
        ShowLaiXe(arrLaiXe) {
            let rs = "";
            if (arrLaiXe.length > 0) {
                arrLaiXe.forEach((e, i) => {
                    if (i > 0) {
                        rs += " - " + e.TenLaiXe;
                    } else {
                        rs += e.TenLaiXe;
                    }
                });
            }
            return rs;
        },
        checkDieuKien(check) {
            if (!!check) {
                return "Đủ điều kiện xuất bến (đăng tài)";
            } else {
                return "Không đủ điều kiện xuất bến (đăng tài)";
            }
        },
        formatDateTime(date) {
            if (date) {
                return moment(date).format("HH:mm:ss DD/MM/YYYY");
            } else {
                return "";
            }
        },
        onScroll(e) {
            this.idNgayFocus = null;
            let heightDown = e.target.scrollHeight - e.target.scrollTop;

            // Load thêm dữ liệu
            // Nếu không phải lọc thì mới cho load thêm
            if (!this.isLoc && this.dataXe.length > 0) {
                if (heightDown == e.target.clientHeight) {
                    this.$emit(
                        "LayThemDuLieu",
                        this.BienKiemSoat,
                        this.dataXe[this.dataXe.length - 1].Ngay,
                        true,
                        10
                    );
                } else if (heightDown == e.target.scrollHeight) {
                    this.$emit(
                        "LayThemDuLieu",
                        this.BienKiemSoat,
                        this.dataXe[0].Ngay,
                        false,
                        10
                    );
                    // Gán ngày để focus
                    this.idNgayFocus = this.GetIdNgay(this.dataXe[0].Ngay);
                }
            }
        },
        GetIdNgay(Ngay) {
            if (!!Ngay) {
                return Ngay.replace(/[^0-9\s]/gi, "");
            }
        },
        XemChiTiet(tuyen) {
            this.ChiTietTuyen = null;
            this.openDetail = true;
            this.ChiTietTuyen = tuyen;
        },
        hiddenDialogCaiDat() {
            this.$emit("hiddenDialogCaiDat");
        },
    },
    created() {
        // for (var i = 0; i < 2000; i++) {
        //     let data = [
        //         {
        //             Ngay: "21/11/2019",
        //             color: this.getRandomColor(),
        //             ChiTiet: [
        //                 {
        //                     id: 1,
        //                     Tuyen: "Thái Nguyên - Lạng Sơn (1112.4134.A)",
        //                     HinhThucChay: "Cố định",
        //                     DieuKienLenNot: "Đủ điều kiện"
        //                 },
        //                 {
        //                     id: 2,
        //                     Tuyen: "Thái Nguyên - Đại Từ (1112.4123.A)",
        //                     HinhThucChay: "Cố định",
        //                     DieuKienLenNot: "Đủ điều kiện"
        //                 }
        //             ]
        //         },
        //         {
        //             Ngay: "22/11/2019",
        //             color: this.getRandomColor(),
        //             ChiTiet: [
        //                 {
        //                     id: 3,
        //                     Tuyen: "Đại Từ - Thái Nguyên (1112.4156.A)",
        //                     HinhThucChay: "Tăng cường",
        //                     DieuKienLenNot: "Đủ điều kiện"
        //                 },
        //                 {
        //                     id: 4,
        //                     Tuyen: "Lạng Sơn - Thái Nguyên (1112.4145.A)",
        //                     HinhThucChay: "Tăng cường",
        //                     DieuKienLenNot: "Đủ điều kiện"
        //                 }
        //             ]
        //         },
        //         {
        //             Ngay: "23/11/2019",
        //             color: this.getRandomColor(),
        //             ChiTiet: [
        //                 {
        //                     id: 5,
        //                     Tuyen: "Lạng Sơn - Đại Từ (1112.4342.A)",
        //                     HinhThucChay: "Tăng cường",
        //                     DieuKienLenNot: "Đủ điều kiện"
        //                 },
        //                 {
        //                     id: 6,
        //                     Tuyen: "Đại Từ - Lạng Sơn (1112.4567.A)",
        //                     HinhThucChay: "Tăng cường",
        //                     DieuKienLenNot: "Đủ điều kiện"
        //                 }
        //             ]
        //         },
        //         {
        //             Ngay: "24/11/2019",
        //             color: this.getRandomColor(),
        //             ChiTiet: [
        //                 {
        //                     id: 3,
        //                     Tuyen: "Thái Nguyên - TP.Hồ Chí Minh (1112.4192.A)",
        //                     HinhThucChay: "Tăng cường",
        //                     DieuKienLenNot: "Đủ điều kiện"
        //                 },
        //                 {
        //                     id: 4,
        //                     Tuyen:
        //                         "TP.Hồ Chí Minh - Thái Nguyên (1112.41899.A)",
        //                     HinhThucChay: "Tăng cường",
        //                     DieuKienLenNot: "Đủ điều kiện"
        //                 }
        //             ]
        //         }
        //     ];
        //     this.dataXe = data;
        // }
    },
};
</script>

<style scoped>
.frame-content-box {
    height: calc(100vh - 56px - 144px);
    overflow-y: hidden;
    overflow-x: hidden;
}
.frame-content-box::-webkit-scrollbar,
.frame-layout::-webkit-scrollbar {
    width: 8px;
    margin-left: 5px;
}
.frame-content-box::-webkit-scrollbar-track,
.frame-layout::-webkit-scrollbar-track {
    border-radius: 10px;
}
.frame-content-box::-webkit-scrollbar-thumb,
.frame-layout::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: #ccc;
}
.frame-content-box::-webkit-scrollbar-thumb:active,
.frame-layout::-webkit-scrollbar-thumb:active {
    background: #ccc;
}

.frame-timeline {
    width: 100%;
    height: max-content;
    display: block;
    float: left;
    padding: 24px;
    position: relative;
}
.frame-content {
    float: left;
    width: 100%;
    padding: 24px 0;
}
.frame-timeline::before {
    content: "";
    height: calc(100% - 48px);
    left: 200px;
    position: absolute;
    top: 24px;
    width: 5px;
    background: rgba(0, 0, 0, 0.12);
}
.frame-content .frame-ngay-hoat-dong {
    position: relative;
    top: 14px;
    float: left;
    width: 160px;
    font-size: 24px;
    font-weight: bold;
}
.frame-content .frame-thong-tin-hoat-dong {
    float: left;
    width: calc(100% - 169px - 80px);
    margin-left: 80px;
    position: relative;
    top: -8px;
}
.frame-content .frame-thong-tin-hoat-dong .content-thong-tin {
    border-radius: 8px;
    box-shadow: 0px 3px 6px rgb(0, 0, 0, 0.16);
    border-top: 1px solid rgb(0, 0, 0, 0.1);
    padding: 16px;
    margin-bottom: 40px;
    position: relative;
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
}
.frame-content .frame-thong-tin-hoat-dong .content-thong-tin:hover {
    cursor: pointer;
    box-shadow: 0px 5px 15px rgb(0, 0, 0, 0.26);
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
}
.content-thong-tin .dot-timeline {
    width: 40px;
    height: 40px;
    position: absolute;
    left: -81.5px;
    top: 14px;
    padding: 1px 4px 4px 5px;
    border-radius: 50%;
    background: #fff;
    box-shadow: 0px 3px 6px rgb(0, 0, 0, 0.16);
}
.content-thong-tin .dot-timeline i {
    font-size: 30px;
}
.frame-content .frame-thong-tin-hoat-dong .content-thong-tin:last-child {
    margin-bottom: 0px;
}
/* .frame-content .frame-thong-tin-hoat-dong .content-thong-tin::before {
    content: "";
    position: absolute;
    top: 8px;
    margin-top: 0;
    left: -30px;
    border: solid 14.5px transparent;
    border-right-color: rgb(0, 0, 0, 0.16);
    z-index: 0;
} */
.content-thong-tin .ten-tuyen {
    font-size: 24px;
    font-weight: 500;
    color: #000;
    margin-bottom: 8px;
}
.content-thong-tin .row-label {
    font-size: 16px;
    font-weight: normal;
    margin-bottom: 4px;
    display: flex;
    align-items: center;
}
/* .content-thong-tin .dieu-kien-len-not {
    font-size: 16px;
    font-weight: normal;
} */

/* Virtual scroll */
.frame-layout {
    width: 100%;
    height: calc(100vh - 56px - 144px - 48px) !important;
    overflow: auto;
}

.arrow {
    content: "";
    position: absolute;
    top: 24px;
    margin-top: 0;
    width: 24px;
    left: -13px;
    height: 24px;
    border-left: solid 1px rgba(0, 0, 0, 0.08);
    border-bottom: solid 1px rgba(0, 0, 0, 0.11);
    /* box-shadow: -2px 2px 2px 0px rgba(0, 0, 0, 0.03); */
    background: #fff;
    border-right: none;
    border-top: none;
    transform: rotate(-315deg);
    z-index: 0;
}

.qr-code-label,
.qr-code-img {
    width: 180px;
    text-align: center;
}
.qr-code-img {
    height: 180px;
    border: 1px solid #dadce0;
    border-radius: 8px;
    padding: 16px;
}
.qr-code-label {
    color: #0620ee;
    padding: 8px;
}
</style>